import optionData from '@/assets/js/optionData';
import { getOfferPage, fetchOfferDel, fetchOfferSubAff } from '@/api/DDJ/v3/offer.js';
import { getAdvertiserList } from '@/api/DDJ/v3/advertiser/index.js';
import { fetchCountryList } from '@/api/DDJ/v3/country.js';
export default {
  components: {},
  data() {
    return {
      filter: {},
      options: {
        country: [],
        os: optionData.osOption,
        status: [
          {
            label: 'active',
            value: 'active',
          },
          {
            label: 'paused',
            value: 'paused',
          },
          {
            label: 'supplement',
            value: 'supplement',
          },
        ],
        advertiser: [],
        offerType: [
          {
            label: 'api',
            value: 'api',
          },
          {
            label: 'shoudong',
            value: 'shoudong',
          },
        ],
      },
      list: [],
      diallog: {
        more: false,
      },
      loading: {
        list: false,
      },
      SubAffData: {
        offerId: '5466sd',
        subAff: '',
      },
      random: '',
      rules: {},
      pages: {
        pageNum: 1,
        pageSize: 10,
      },
      pagesTotal: 0,
      isShowPage: false,
    };
  },
  mounted() {
    this.getAdvertiser();
    this.getCountry();
    this.filterFun();
  },
  computed: {},
  methods: {
    // 获取列表
    filterFun(types) {
      if (types == 'filter') {
        this.pages.pageNum = 1;
        this.isShowPage = false;
      }
      let query = Object.assign(this.filter, this.pages);
      this.loading.list = true;
      getOfferPage(query).then((res) => {
        let data = res.result;
        this.list = [];
        this.list = data.records;
        this.pagesTotal = data.total;
        this.loading.list = false;
        this.isShowPage = true;
      });
    },
    // 获取advertiser
    getAdvertiser() {
      getAdvertiserList().then((res) => {
        this.options.advertiser = res.result;
      });
    },
    // 获取country
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },
    // 获取 sub aff
    getSubAff(offerId, advertiserName) {
      fetchOfferSubAff({
        advertiserName: advertiserName,
        offerId: offerId,
      }).then((res) => {
        console.log(res);
        this.diallog.more = true;
      });
    },
    addTask(offerId, country, id, os, advertiserName) {
      this.$router.push({
        path: '/click/task/add/v3',
        query: {
          offerId: offerId,
          country: country,
          id: id,
          os: os,
          advertiserName: advertiserName,
        },
      });
    },
    edit(id) {
      this.$router.push({
        path: '/click/offer/edit/v3',
        query: {
          id: id,
        },
      });
    },
    add() {
      this.$router.push({
        path: '/click/offer/add/v3',
      });
    },
    del(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          console.log(id);
          fetchOfferDel(id).then((res) => {
            console.log(res);
            this.filterFun();
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    more(offerId, advertiserName) {
      this.SubAffData.offerId = offerId;
      this.getSubAff(offerId, advertiserName);
    },
    // 生成随机
    randomFun() {
      var len = 15;
      var $chars =
        'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      var maxPos = $chars.length;
      var pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      this.random = pwd;
    },
    // 格式化subAff
    getSubAffStr() {
      let subAffSplit = this.postData.subAff.replaceAll('\n', ',').split(',');
      let returnSubAff;
      for (let i = 0; i < subAffSplit.length; i++) {
        if (subAffSplit[i] != '' && subAffSplit[i] != 'undefined') {
          if (i == 0) {
            returnSubAff = subAffSplit[i].trim();
          } else {
            returnSubAff = returnSubAff + ',' + subAffSplit[i].trim();
          }
        }
      }
      return returnSubAff;
    },
    // 保存子渠道配置
    submitSubAff() {
      this.diallog.more = false;
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.filterFun();
    },
  },
};
